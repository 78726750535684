import { USER_ROLE_VALUE } from "@/enums/user-role.enum"

export function getFromLocalStorage (key) {
  return localStorage.getItem(key)
}

export function setToLocalStorage (key, value) {
  localStorage.setItem(key, value)
  return value
}

export function deleteFromLocalStorage (key) {
  localStorage.removeItem(key)
}

export function getTokenPayload () {
  const token = getFromLocalStorage('accessToken')
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }
  return null
}

export function getUserRole () {
  const payload = getTokenPayload()
  if (payload) {
    return USER_ROLE_VALUE[payload.role]
  }
  return null
}

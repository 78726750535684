import { GET_USERS_PAGINATION_QUERY } from "@/graphql/queries/user/get-users-pagination.query"
import { RESEND_USERS_PASSWORD_MUTATION } from "@/graphql/mutations/resend-users-password.mutation"
import { handleGqlResponseBody } from "@/utils/apollo.util"
import {
  CREATE_ADMIN_USER_MUTATION,
  CREATE_PROPERTY_GROUP_MANAGER_USER_MUTATION,
  CREATE_PROPERTY_OWNER_USER_MUTATION
} from '@/graphql/mutations/user-cud/create.mutation'
import { USER_ROLE } from '@/enums/user-role.enum'
import { UPDATE_USER_MUTATION } from '@/graphql/mutations/user-cud/update.mutation'
import { DELETE_USER_MUTATION } from '@/graphql/mutations/user-cud/delete.mutation'
import { GET_USER_QUERY } from '@/graphql/queries/user/get-user.query'

const user = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getUserQuery ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_USER_QUERY,
          variables: { id },
          fetchPolicy: "no-cache"
        })
      })
    },
    async getUsersQuery ({ commit }, {
      skip, take, orderBy, isActive, fullName, userRole
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_USERS_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, isActive, fullName, userRole
          },
          fetchPolicy: "no-cache"
        })
      })
    },
    async resendUsersPassword ({ commit }, { usersId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: RESEND_USERS_PASSWORD_MUTATION,
          variables: { usersId },
          fetchPolicy: "no-cache"
        })
      })
    },
    async createUser ({ commit }, {
      firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, role, ownerOfPropertyId
    }) {
      const mutation = role === USER_ROLE.ADMIN
        ? CREATE_ADMIN_USER_MUTATION
        : (
            role === USER_ROLE.PROPERTY_GROUP_MANAGER ? CREATE_PROPERTY_GROUP_MANAGER_USER_MUTATION : CREATE_PROPERTY_OWNER_USER_MUTATION
          )
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation,
          variables: {
            firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, ownerOfPropertyId
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async updateUser ({ commit }, {
      id, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, ownerOfPropertyId
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            id, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, ownerOfPropertyId
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async deleteUser ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: DELETE_USER_MUTATION,
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default user

import gql from "graphql-tag"

export const VACATE_PROPERTY_ROOM_MUTATION = gql`
  mutation VacatePropertyRoomMutation($propertyRoomId: Int!, $defaultPassword: String!, $roomNumber: String!) {
    vacatePropertyRoom(
      data: {
        propertyRoomId: $propertyRoomId
        defaultPassword: $defaultPassword
        roomNumber: $roomNumber
      }
    ) { result }
  }
`

import gql from 'graphql-tag'

export const GET_FAMILY_MEMBER_IMAGES = gql`
  query getFamilyMemberImages ( $id: Int! ) {
    getFamilyMemberImages(id: $id) {
      id
      fullUri
    }
  }
`

import { GET_DELIVERIES_FOR_USERS } from "@/graphql/delivery/delivery-graphql"
import { handleGqlResponseBody } from "@/utils/apollo.util"

const evStation = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDeliveriesForUsers (_ctx, {
      skip, take, orderBy, data
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_DELIVERIES_FOR_USERS,
          variables: {
            skip, take, orderBy, ...data
          },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default evStation

import gql from 'graphql-tag'

export const CREATE_ADMIN_USER_MUTATION = gql`
  mutation CreateAdminUser (
    $firstName: String!,
    $firstNameKana: String,
    $lastName: String!,
    $lastNameKana: String,
    $email: String!,
    $telNumber: String!,
    $isActive: Boolean!
  ){
    createAdminUser(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
      }
    ) {
      id
    }
  }
`

export const CREATE_PROPERTY_GROUP_MANAGER_USER_MUTATION = gql`
  mutation createPropertyGroupManagerUser (
    $firstName: String!,
    $firstNameKana: String,
    $lastName: String!,
    $lastNameKana: String,
    $email: String!,
    $telNumber: String!,
    $isActive: Boolean!
  ){
    createPropertyGroupManagerUser(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
      }
    ) {
      id
    }
  }
`

export const CREATE_PROPERTY_OWNER_USER_MUTATION = gql`
  mutation createPropertyOwnerUserV2 (
    $firstName: String!,
    $firstNameKana: String,
    $lastName: String!,
    $lastNameKana: String,
    $email: String!,
    $telNumber: String!,
    $isActive: Boolean!
    $ownerOfPropertyId: Int
  ){
    createPropertyOwnerUserV2(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
        ownerOfPropertyId: $ownerOfPropertyId
      }
    ) {
      id
    }
  }
`

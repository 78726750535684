export const USER_ROLE =
  {
    ADMIN: 'ADMIN',
    PROPERTY_OWNER: 'PROPERTY_OWNER',
    PROPERTY_GROUP_MANAGER: 'PROPERTY_GROUP_MANAGER',
    REPRESENTATIVE_RESIDENT: 'REPRESENTATIVE_RESIDENT'
  }

export const USER_ROLE_VALUE =
  {
    admin: 'ADMIN',
    property_owner: 'PROPERTY_GROUP_MANAGER',
    property_owner_v2: 'PROPERTY_OWNER',
    representative_resident: 'REPRESENTATIVE_RESIDENT'
  }

export const USER_ROLE_TRANSLATE =
  {
    ADMIN: '管理者',
    PROPERTY_GROUP_MANAGER: 'グループ管理者',
    PROPERTY_OWNER: '物件オーナー',
    REPRESENTATIVE_RESIDENT: '代表的な居住者'
  }

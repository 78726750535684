import gql from 'graphql-tag'

export const GET_PROPERTY_ROOMS_PAGINATION_QUERY = gql`
  query GetPropertyRoomsPaginationQuery (
    $number: String
    $representativeFullName: String
    $representativeFullNameKana: String
    $representativeTelNumber: String
    $propertyFloorId: Int!
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getPropertyRooms(
      data: {
        number: $number
        representativeFullName: $representativeFullName
        representativeFullNameKana: $representativeFullNameKana
        representativeTelNumber: $representativeTelNumber
        propertyFloorId: $propertyFloorId
        pagination: {
          skip: $skip
          take: $take
        }
        orderBy: $orderBy
      }
    ){
      hasMore
      total
      items {
        id
        number
        serialNumber
        status
        familyMember {
          id
          username
          passcode
        }
        representativeAccount {
          id
          firstName
          lastName
          firstNameKana
          lastNameKana
          email
          telNumber
        }
      }
    }
  }
`

import Vue from 'vue'
import Vuex from 'vuex'
import authentication from "@/store/modules/authentication"
import currentUser from "@/store/modules/current-user"
import loading from "@/store/modules/loading"
import user from "@/store/modules/user"
import property from "@/store/modules/property"
import propertyFloor from "@/store/modules/property-floor"
import propertyRoom from "@/store/modules/property-room"
import loginHistory from '@/store/modules/user/login-history'
import intercom from '@/store/modules/intercom'
import app from './modules/app'
import evStation from './modules/ev-station'
import delivery from './modules/delivery'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authentication,
    currentUser,
    user,
    loading,
    property,
    propertyFloor,
    propertyRoom,
    loginHistory,
    intercom,
    app,
    evStation,
    delivery
  }
})

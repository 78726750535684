export function requiredValidator (value) {
  return !!value || '必須項目です。'
}

export const checkPostalCode = val => {
  const regex = /^\d{7}$/
  return regex.test(val)
}

export const checkCodeForNumber = val => {
  const regex = /^\d+$/
  return regex.test(val)
}

export const checkTelNumber = val => {
  if (!val) return true
  const regex = /^[0]\d{1,3}[-]?\d{1,4}[-]?\d{3,4}$/
  return regex.test(val)
}

export const checkEmail = val => {
  const regex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(val)
}

export const checkDefaultPassword = val => {
  const regex = /^[0-9]{8}$/
  return regex.test(val)
}

export default {
  required: requiredValidator,
  postalCode: checkPostalCode,
  integer: checkCodeForNumber,
  tel: checkTelNumber,
  email: checkEmail,
  defaultPassword: checkDefaultPassword
}

import gql from 'graphql-tag'

export const GET_USERS_PAGINATION_QUERY = gql`
  query GetUsersPagination (
    $orderBy: [OrderByInput!],
    $skip: Int!,
    $take: Int!,
    $isActive: Boolean
    $fullName: String
    $userRole: UserRole
  ) {
    getUsers(
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        isActive: $isActive
        fullName: $fullName
        userRole: $userRole
      }
    ){
      hasMore
      total
      items {
        id
        isActive
        email
        telNumber
        firstName
        lastName
        role
      }
    }
  }
`

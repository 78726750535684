const defaultMessage = 'エラーが発生しました。お時間をおいてもう一度お試し下さい'

export function gqlErrorToast (toast, gqlError, except = []) {
  const excluded = {}
  let hasError = false

  const handleErrors = (errors) => {
    for (let i = 0; i < errors.length; i++) {
      const e = errors[i]
      const exceptFound = except.find(message => message === e.message)
      if (exceptFound) {
        excluded[exceptFound] = e
      } else {
        hasError = true
        toast.error(
          translateGraphQLError(e.code, e.message)
        )
      }
    }
  }

  if (gqlError.networkError) {
    handleErrors(gqlError.networkError.result.errors)
  } else if (gqlError.graphQLErrors) {
    handleErrors(gqlError.graphQLErrors)
  } else {
    toast.error(defaultMessage)
  }

  return {
    excluded,
    hasError
  }
}

const translations = {
  CC_INVALID_TOKEN: '決済処理に失敗しました。もう一度カード番号を入力してください。',
  BOX_NUMBER_INVALID_FORMAT: '扉錠番号のフォーマットが不正です。',
  DELIVERY_BOX_TABLET_CONTROL_REJECTED: 'タブレット側で操作を受け付けることができませんでした',
  DELIVERY_BOX_TABLET_CONTROL_TIMEOUT: 'タブレットへの要求がタイムアウトしました',
  INSUFFICIENT_ROLE: 'ご指定の処理を実行する権限がありません。',
  FAMILY_MEMBER_NOT_FOUND: 'ご指定のアカウントは見つかりませんでした。',
  FAMILY_MEMBER_NOT_REPRESENTATIVE: 'ご指定のアカウントは代表者ではありません。'
}

const defaultMessageByErrorCode = {
  NOT_FOUND_ERROR: 'お探しの情報は見つかりませんでした。',
  ACCESS_DENIED_ERROR: 'ご指定の情報へのアクセスが拒否されました。',
  BAD_CREDENTIAL_ERROR: 'パスワード認証に失敗しました。入力内容をお確かめの上再度お試し下さい。',
  BAD_INPUT_ERROR: '不正な入力内容があります。入力内容をお確かめの上再度お試し下さい。',
  INVALID_MFA_CODE_ERROR: 'ログインに失敗しました。再度最初からお試し下さい。',
  INVALID_CHANGE_PASSWORD_CODE_ERROR: 'パスワード変更に失敗しました。再度最初からお試し下さい。',
  UNAUTHORIZED_ERROR: 'ご指定の情報へのアクセス権限がありません。',
  UNEXECUTABLE_ERROR: 'ご指定の処理が完了できませんでした。'
}

export function translateGraphQLError (code, message) {
  return translations[message] ||
    defaultMessageByErrorCode[code] ||
    defaultMessage
}

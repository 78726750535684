import gql from 'graphql-tag'

export const REQUEST_REP_MEMBER_DELETE_MUTATION = gql`
  mutation requestDeleteRepresentativeMember ($email: String!) {
    requestDeleteRepresentativeMember(
      email: $email
    ){
      result
    }
  }
`

import gql from 'graphql-tag'

export const GET_USER_QUERY = gql`
  query GetUserQuery ( $id: Int! ) {
    getUser(data: { id: $id }) {
      id
      email
      firstName
      firstNameKana
      lastName
      lastNameKana
      telNumber
      isActive
      role
      permissions {
        propertyId
      }
    }
  }
`

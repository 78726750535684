import gql from 'graphql-tag'

export const GET_DELIVERIES_FOR_USERS = gql`
query getDeliveriesForUsers(
  $propertyId: Int!
  $fromDate: DateString
  $toDate: DateString
  $deliveryBoxUnitIds: [Int!]
  $deliveryCarrierIds: [Int!]
  $shipmentNumber: String
  $skip: Int!
  $take: Int!
  $orderBy: [OrderByInput!]
) {
  getDeliveriesForUsers(data: {
    propertyId: $propertyId
    fromDate: $fromDate
    toDate: $toDate
    deliveryBoxUnitIds: $deliveryBoxUnitIds
    deliveryCarrierIds: $deliveryCarrierIds
    shipmentNumber: $shipmentNumber
    pagination: {
      skip: $skip
      take: $take
    }
    orderBy: $orderBy
  }) {
    total
    items {
      deliveryConfirmedAt
      receivedAt
      resetAt
      deliveryBoxUnit { boxNumber }
      deliveryCarrier { name }
      shipmentNumber
      propertyRoom { number }
    }
  }
}
`

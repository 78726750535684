<template>
  <v-main>
    <SidebarLayout @is-sidebar-open="onSidebarStateChange" v-if="!isSidebarHidden"></SidebarLayout>
    <div class="main-layout-container" :class="{ 'sidebar-open' : isSidebarOpen }">
      <slot />
    </div>

    <OperationExecutionConfirmationModal
      v-if="isConfirmOpen"
      visible
      :loading="confirm.loading"
      :description="confirm.description"
      :data="confirm.data"
      @close="$store.commit('closeConfirm')"
      @confirm="$store.dispatch('clickConfirm')"
    />
    <v-overlay :value="$store.state.app.loading" style='z-index:20001;'>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import OperationExecutionConfirmationModal from '@/components/OperationExecutionConfirmationModal'
import SidebarLayout from '../layouts/SidebarLayout'

export default {
  name: 'MainLayout',
  components: { SidebarLayout, OperationExecutionConfirmationModal },
  data: () => {
    return {
      isSidebarOpen: false,
      isSidebarHidden: false
    }
  },
  computed: {
    isConfirmOpen () {
      return this.$store.getters.isConfirmOpen
    },
    confirm () {
      return this.$store.state.app.confirm
    }
  },
  mounted () {
    this.isSidebarHidden = this.$route.query.hideSidebar === '1'
  },
  methods: {
    onSidebarStateChange (value) {
      this.isSidebarOpen = value
    }
  }
}
</script>

<style scoped>
  .main-layout-container.sidebar-open {
    width: calc(100% - 65px);
    margin-left: 65px;
  }
</style>

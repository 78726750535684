import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/styles/styles.scss'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueApollo from 'vue-apollo'
import { apolloProvider } from "@/plugins/apollo"
import ShowGqlError from "@/plugins/show-gql-error"
import * as filters from './filters'
import rules from './utils/common.validator'
import AppDialog from '@/components/common/AppDialog'
import { getUserRole } from './utils/local-storage.util'

// install the vue plugin
Vue.use(VueApollo)
Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000
})
Vue.use(ShowGqlError, {
  toast: Vue.prototype.$toast
})
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.component('app-dialog', AppDialog)

Vue.config.productionTip = false

const isDaikoku = process.env.VUE_APP_DAIKOKU === 'true'
const evEnabled = process.env.VUE_APP_ENABLE_EV === 'true'
const deliveryEnabled = process.env.VUE_APP_ENABLE_DELIVERY === 'true' || true
Vue.prototype.$isDaikoku = isDaikoku
Vue.prototype.$evEnabled = evEnabled
Vue.prototype.$deliveryEnabled = deliveryEnabled
Vue.prototype.$appName = isDaikoku ? '大黒ネッツEV' : 'くじらリアルエステートテック'
Vue.prototype.$confirm = payload => store.dispatch('openConfirm', { ...payload, type: 'confirm' })
Vue.prototype.$rules = rules
Vue.prototype.$role = getUserRole

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

import store from "@/store"
import { gqlErrorToast } from "@/utils/graphql-errors"

export default {
  install (Vue, { toast }) {
    async function showGqlError (promise, errorCb = () => {}, except = {}) {
      try {
        return await (typeof promise === 'function' ? promise() : promise)
      } catch (e) {
        console.error(e)
        const exceptMessages = Object.keys(except)
        const { excluded } = gqlErrorToast(toast, e, exceptMessages)
        for (const key of Object.keys(excluded)) {
          except[key]?.(excluded[key])
        }
        // throw e
        if (errorCb) errorCb(e)
        else throw e
      }
    }
    /**
     *
     * @param {function(): Promise<void>} promise
     * @param {{[errorCode: string]: (error: any) => any }} except
     * @param errorCb: () => void
     */
    Vue.prototype.$showGqlError = async function (promise, errorCb = () => {}, except = {}) {
      return showGqlError(promise, errorCb, except)
    }

    Vue.prototype.$gql = async function (promise, options) {
      store.commit('setLoading', true)
      try {
        const result = await showGqlError(promise, null, options?.except ?? {})
        if (options?.showSuccess) {
          toast.success(options?.successMessage ?? '処理が完了しました')
        }
        return result
      } finally {
        store.commit('setLoading', false)
      }
    }
  }
}

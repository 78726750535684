import gql from 'graphql-tag'

export const GET_PROPERTY_ROOM_QUERY = gql`
  query GetPropertyRoomQuery ( $id: Int! ) {
    getPropertyRoom(data: { id: $id }) {
      id
      number
      icCards { id cardId }
      familyMember {
        id
        username
        passcode
        representativeAccountId
      }
      representativeAccount {
        id
        firstName
        lastName
        firstNameKana
        lastNameKana
        isActive
        email
        telNumber
      }
      familyMembers {
        id
        username
        email
        isRepresentative
        type
      }
    }
  }
`

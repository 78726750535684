import gql from 'graphql-tag'

export const UPDATE_PROPERTY_ROOM_REPRESENTATIVE_USER_V2_MUTATION = gql`
  mutation UpdatePropertyRoomRepresentativeUserV2 (
    $propertyRoomId: Int!
    $email: String
    $firstName: String
    $lastName: String
    $firstNameKana: String
    $lastNameKana: String
    $isActive: Boolean
    $telNumber: String
    $icCardIds: [String!]
    $familyMembers: [AdminUpdateFamilyMemberInput!]
  ) {
    updatePropertyRoomRepresentativeUserV2(
      data: {
        propertyRoomId: $propertyRoomId
        email: $email
        firstName: $firstName
        lastName: $lastName
        firstNameKana: $firstNameKana
        lastNameKana: $lastNameKana
        isActive: $isActive
        telNumber: $telNumber
        familyMembers: $familyMembers
      }
      roomInfo: {
        icCardIds: $icCardIds
      }
    ){
      id
    }
  }
`
